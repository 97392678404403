import store from '@/store';
import { ITranslations } from '../../translations/index';

export const inputs = () => {
  const translations: ITranslations = store.getters.getTranslations;
  return {
    name: {
      id: 'input-name',
      name: 'name',
      label: translations.common.name,
      placeholder: translations.common.name_placeholder,
      value: '',
    },
    email: {
      id: 'input-email',
      name: 'email',
      type: 'email',
      label: translations.auth.login.email,
      placeholder: translations.auth.login.email_placeholder,
      value: '',
      disabled: false,
    },
    profile_pic: {
      id: 'input-profile_pic',
      name: 'profile_pic',
      type: 'file',
      placeholder: 'Upload your profile pic',
      value: '',
      error: false,
      message: '',
      onFileSelect: (file: File) => {
        console.log(file);
      },
    },
    password: {
      id: 'input-password',
      name: 'password',
      type: 'password',
      label: translations.auth.login.password,
      placeholder: translations.auth.login.password_placeholder,
      value: '',
    },
    passwordConfirm: {
      id: 'input-password-confirm',
      name: 'password-confirm',
      type: 'password',
      label: translations.account.settings.password_confirm,
      placeholder: translations.account.settings.password_confirm_placeholder,
      value: '',
    },
  };
};

export const form = {
  name: '',
  email: '',
  file: {} as File,
  password: '',
  confirmPassword: '',
};
