import { MutationPayload } from 'vuex';
import { defineComponent, reactive } from '@vue/composition-api';
import {
  editUser, uploadProfilePic, editPassword,
} from '@/api/users';
import Input from '@/components/form/input.vue';
import { IUser } from '@/models/user';
import Modal from '@/components/modal.vue';
import { IAuth } from '@/models/auth';
import store from '@/store';
import Cropper from '@/components/form/cropper.vue';
import UserCard from '@/partials/UserCard.vue';
import { IUserCard } from '@/partials';
import useContext, { IUseContext } from '@/composition/context';
import useAuth from '@/composition/auth';
import { inputs, form } from './index';

export default defineComponent({
  components: {
    Input, Modal, Cropper, UserCard,
  },
  setup() {
    const {
      translations,
      userAdmin,
      contextVisitor,
    } = (useContext() as unknown) as IUseContext;
    const { currentUser, userAuthState } = useAuth();
    const state = reactive({
      modal: {
        isActive: false,
        isCard: true,
      },
      cropper: {
        image: '',
        aspectRatio: 1 / 1,
        onSelect: (blob: string) => { console.log(blob); },
      },
      cropLoading: false,
      cropping: true,
      croppedFileName: '',
      cropSrc: '',
      confirming: false,
      loadingPassword: false,
      loadingAccount: false,
      inputs: inputs(),
      form,
      cardConfig: {} as IUserCard,
    });
    const confirmCrop = () => {
      state.inputs.profile_pic.error = false;
      state.cropLoading = true;
      if (currentUser.value.name) {
        state.cardConfig = {
          name: currentUser.value.name, detail: currentUser.value.email, picture: state.cropSrc,
        };
      }

      const user = {
        id: currentUser.value.id,
        name: state.form.name,
        email: state.form.email,
        profile_picture: currentUser.value.profile_picture,
      };
      const imageForm = new FormData();
      imageForm.append('file', state.form.file);

      uploadProfilePic(user, imageForm).then((response) => {
        const profilePic = response.data.profile_picture;
        state.cardConfig = {
          name: user.name, detail: user.email, picture: profilePic,
        };
        store.commit('setUserProfilePic', profilePic);
        user.profile_picture = profilePic;
        syncLocalStorageData(user);
        state.modal.isActive = false;
      });
    };

    const cancel = () => {
      state.modal.isActive = false;
      if (currentUser.value.name) {
        state.cardConfig = {
          name: currentUser.value.name, detail: currentUser.value.email, picture: currentUser.value.profile_picture,
        };
      }
    };

    const onSelectCrop = (blob: string) => {
      const file = new File([blob], state.croppedFileName, { type: 'image/png' });
      const urlCreator = window.URL || window.webkitURL;
      state.form.file = file;
      state.cropSrc = urlCreator.createObjectURL(blob);

      state.cropping = false;
      state.confirming = true;
    };

    state.cropper.onSelect = onSelectCrop;

    const syncLocalStorageData = (user: IUser) => {
      // Update authentication
      const auth: IAuth = { token: userAuthState.value.token, user };
      store.commit('setUserLogin', auth);

      // Sync visitor name
      if (contextVisitor.value && contextVisitor.value.id && user.name) {
        store.commit('setContextVisitor', {
          ...contextVisitor.value, name: user.name, id: contextVisitor.value.id,
        });
      }
      const localStorageProp = process.env.VUE_APP_LOCAL_STORAGE_AUTH_PROP;

      localStorage.setItem(
        localStorageProp,
        JSON.stringify({
          auth,
          userLoggedIn: true,
        }),
      );
    };

    const changePassword = () => {
      state.loadingPassword = true;
      editPassword(state.form.password).then(() => {
        state.loadingPassword = false;
      });
    };

    const selectImage = (file: File) => {
      state.croppedFileName = file.name;
      if (state.form.file) {
        if (state.form.file.name.match(/.(jpg|jpeg|png|gif)$/i)) {
          const reader = new FileReader();
          reader.onload = (e) => {
            if (e && e.target && e.target.result) {
              state.cropper = { ...state.cropper, image: e.target.result as string };
              state.cropping = true;
              state.confirming = false;
              state.modal.isActive = true;
            }
          };
          reader.readAsDataURL(file);
        } else {
          state.inputs.profile_pic = {
            ...state.inputs.profile_pic,
            value: '',
            message: translations.value.common.file_not_image,
            error: true,
          };
        }
      }
    };

    const goBack = () => {
      window.history.back();
    };

    const saveChanges = () => {
      const user = {
        id: currentUser.value.id,
        name: state.form.name,
        email: state.form.email,
        profile_picture: currentUser.value.profile_picture,
      };
      state.loadingAccount = true;
      editUser(user).then(() => {
        state.loadingAccount = false;
        syncLocalStorageData(user);
      });
    };

    if (currentUser.value.name || contextVisitor.value.name) {
      state.form.name = currentUser.value.name || contextVisitor.value.name;
      state.inputs.name = {
        ...state.inputs.name,
        value: state.form.name,
      };
      state.cardConfig = {
        name: state.form.name, detail: currentUser.value.email, picture: currentUser.value.profile_picture,
      };
    } else if (!userAdmin.value) {
      // Get the latest user info
      const unsubscribe = store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setUserLogin') {
          if (currentUser.value.name) {
            state.cardConfig = {
              name: currentUser.value.name, detail: currentUser.value.email, picture: currentUser.value.profile_picture,
            };
          }
          unsubscribe();
        }
      });
    } else {
      state.cardConfig = {
        name: 'Admin', detail: currentUser.value.email, picture: currentUser.value.profile_picture,
      };
    }

    state.inputs.profile_pic.onFileSelect = (file: File) => {
      selectImage(file);
    };
    if (currentUser.value.email) {

      state.form.email = currentUser.value.email;

      state.inputs.email = {
        ...state.inputs.email,
        value: currentUser.value.email,
        disabled: true,
      };
    }

    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setLanguage') {
        state.inputs = inputs();
      }
    });

    return {
      state, currentUser, userAuthState, translations, userAdmin, goBack, saveChanges, changePassword, confirmCrop, cancel,
    };
  },
});
